<template>
  <div class="main-content">
    <b-card>
      <GoodDataTable 
        ref="dataTable" 
        class="w-100" 
        mode="local" 
        :no-serial="true" 
        :columns="columns"
        :load-local-data="true" 
      />
    </b-card>
    <b-modal v-model="detailModel" hide-footer size="lg">
        <div class="row">
            <div class="col-12">
                <GoodDataTable 
                    ref="dataTableDetails" 
                    class="w-100" 
                    mode="local" 
                    :no-serial="true" 
                    :columns="columnsDetails"
                    :load-local-data="true" 
                />
            </div>
        </div>
    </b-modal>
  </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import GoodDataTable from "@/@core/layouts/shared/GoodDataTable.vue";
import { dateTimeFormat, isEmpty } from '@/utilities';
import { hideLoader, showLoader } from "@/@core/comp-functions/ui/app";

const reqStatusOptions = [
    { value: 1, text: 'Pending' },
    { value: 2, text: 'Approved' },
    { value: 3, text: 'Payment Processing' },
    { value: 4, text: 'Completed' },
    { value: 5, text: 'Reject Request' },
]

const columnsDetails = [
    {
        field: 'commission_rate',
        label: 'Commission Percentage',
        sortable: false,
    },
    {
        field: 'amount',
        label: 'Amount',
        useResolver: true,
        useHtml: true,
        renderer: props => `$${props.amount}`,
    },
    {
        field: 'created_at',
        label: 'Date',
        useResolver: true,
        useHtml: true,
        renderer: props => isEmpty(props.created_at) ? 'N/A' : dateTimeFormat(props.created_at, 'DD MMM, YYYY'),
    },
    {
        field: 'referral_type',
        label: 'Referral Type',
    },
    {
        field: 'referral',
        label: 'Referral',
    },
]
export default {

  name: "PaymentsIndex",

  components: {
    GoodDataTable
  },

  data: () => ({
    columnsDetails,
    detailModel: false,
  }),

  computed: {
    ...mapGetters('affilation', [
        'payout_requests'
    ]),
    columns: {
      get(){
        return [
          {
              field: 'created_at',
              label: 'Date',
              useResolver: true,
              useHtml: true,
              renderer: props => isEmpty(props.created_at) ? 'N/A' : dateTimeFormat(props.created_at, 'DD MMM, YYYY'),
          },
          {
              field: 'requested_amount',
              label: 'Amount',
              useResolver: true,
              useHtml: true,
              renderer: props => `$${props.requested_amount}`,
          },
          {
              field: 'status',
              label: 'Status',
              useResolver: true,
              useHtml: true,
              renderer: props => {
                const status = reqStatusOptions.find(i => i.value == props.status)
                return status?.text
              }
          },
          {
              label: 'Action',
              field: 'action',
              sortable: false,
              type: 'dropdown',
              buttons: [
                  {
                      icon: 'View Details',
                      title: 'View Details',
                      image: this.$helpers.getIcons('view'),
                      action: props => {
                          this.openModal(props)
                      },
                  },
              ],
          }
      ]
      }
    }
  },

  methods: {
    isEmpty, dateTimeFormat,
    ...mapActions('affilation', [
        'getPayoutRequests', 
        'getPayoutRequestSubItems',
    ]),
    setData(){
        if(this.$refs.dataTable){
            this.$refs.dataTable.setLocalData(this.payout_requests)
        }
    },
    async openModal(req) {
        showLoader()
        this.detailModel = true
        this.getPayoutRequestSubItems(req.id).then(r => {
            if(r._statusCode === 200){
                this.$refs.dataTableDetails.setLocalData(r.responseData)
            }
            hideLoader()
        }).catch(e => {
            hideLoader()
        })
    },
  },

  mounted() {
    this.getPayoutRequests()
    this.setData()
  },

  watch: {
    payout_requests: {
      handler(v) {
        this.setData()
      }
    }
  }
}
</script>